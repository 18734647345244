'use client';

import { useEffect, useRef } from 'react';
import { locationDataLoaded } from '../routing/location/locationChangeActions';
import { usePageHistory } from '../pageWrappers/usePageHistory';
import { useDOPDispatch } from './reduxHooks';
import { usePathname } from 'next/navigation';
import {
	WebApi404Data,
	WebApiPageData,
} from '../dataCollector/types/dataCollector.types';

export const UseDispatchLocationDataLoaded = ({
	status,
	opPartner,
	providerType,
	url,
}: {
	status: WebApiPageData['status'] | WebApi404Data['status'];
	opPartner: WebApiPageData['metadata']['op:partner'];
	providerType: WebApiPageData['providerType'];
	url: WebApiPageData['url'];
}) => {
	const pathnameRef = useRef<unknown | undefined>(undefined);
	const [, dispatchPageHistory] = usePageHistory();
	const dispatch = useDOPDispatch();
	const pathname = usePathname();

	useEffect(() => {
		// Only fire dispatch if the pathname has changed
		if (pathnameRef.current === pathname) {
			return;
		}
		pathnameRef.current = pathname;
		dispatch(
			locationDataLoaded({
				status: 200,
			})
		);

		if (status === '404') {
			dispatchPageHistory({
				partners: undefined,
				providerType: undefined,
				pathname,
			});
		} else {
			dispatchPageHistory({
				partners: opPartner,
				providerType: providerType,
				pathname: url,
			});
		}
	}, [
		dispatch,
		dispatchPageHistory,
		opPartner,
		pathname,
		providerType,
		status,
		url,
	]);
	return null;
};
