import {
	LinkMeta,
	locationChange,
	locationChangeInNewWindow,
} from '@/globals/routing/location/locationChangeActions';
import { ReduxDispatch } from '@/globals/dataProviders/store';

import { getInternalHref, HrefKind } from '../functions/getLinkInfo';

export const dispatchLinkAction = ({
	hrefKind,
	url,
	linkMeta,
	dispatch,
}: {
	hrefKind: HrefKind;
	url: URL;
	linkMeta?: LinkMeta;
	dispatch: ReduxDispatch;
}) => {
	switch (hrefKind) {
		case 'EXTERNAL':
		case 'NON_HTTP':
			return dispatch(
				locationChange({
					href: url.href,
					hrefKind,
					linkMeta,
				})
			);
		case 'INTERNAL_SELF':
		case 'INTERNAL':
		case 'INTERNAL_STATIC_CONTENT':
			return dispatch(
				locationChange({
					href: getInternalHref(url),
					hrefKind,
					linkMeta,
				})
			);
	}
};

export const dispatchNewWindowLinkAction = ({
	hrefKind,
	url,
	linkMeta,
	dispatch,
}: {
	hrefKind: HrefKind;
	url: URL;
	linkMeta: LinkMeta | undefined;
	dispatch: ReduxDispatch;
}) => {
	switch (hrefKind) {
		case 'EXTERNAL':
		case 'NON_HTTP':
			dispatch(
				locationChangeInNewWindow({
					href: url.href,
					hrefKind,
					linkMeta,
				})
			);
			break;
		case 'INTERNAL':
		case 'INTERNAL_SELF':
		case 'INTERNAL_STATIC_CONTENT':
			dispatch(
				locationChangeInNewWindow({
					href: getInternalHref(url),
					hrefKind,
					linkMeta,
				})
			);
	}
};
