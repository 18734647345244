'use client';

import './MopinionSurvey.css';
import { useEffect, useState } from 'react';
import { LoadMopinion, MopinionSRV } from './LoadMopinion';
import { createExpirableLocalStorage } from '@/globals/webStorage/expirableLocalStorage';
import { getMSFromDays } from '@dop/shared/helpers/toMicroSecondsHelpers';
import * as v from 'valibot';

const getSessionSurveyShownCount = () => {
	try {
		const count = window.sessionStorage.getItem('surveyShownCount');
		if (count == null) return 0;
		return parseInt(count, 10);
	} catch (error) {
		return 0;
	}
};
const setSessionSurveyShownCount = (count: number) => {
	try {
		window.sessionStorage.setItem('surveyShownCount', count.toString());
	} catch (error) {
		// NOOP
	}
};

const surveyDoneExpirableLocalStorage = createExpirableLocalStorage({
	key: 'surveyPopupDone',
	maxAgeInMS: getMSFromDays(31),
	schema: v.boolean(),
});

export const MopinionSurvey = ({
	surveyID,
	delayInSeconds,
	reminderDelayInSeconds,
}: {
	surveyID: string;
	delayInSeconds: number;
	reminderDelayInSeconds: number;
}) => {
	const [surveyPoppedUp, setSurveyPoppedUp] = useState(false);
	const [mopinionSRV, setMopinionSRV] = useState<MopinionSRV | null>(null);

	useEffect(() => {
		if (surveyDoneExpirableLocalStorage.get()) return;

		const delay =
			getSessionSurveyShownCount() === 0
				? delayInSeconds
				: reminderDelayInSeconds;

		const timer = setTimeout(() => {
			if (surveyDoneExpirableLocalStorage.get()) return;

			setSurveyPoppedUp(true);
		}, delay * 1000);

		return () => {
			clearTimeout(timer);
		};
	}, [delayInSeconds, reminderDelayInSeconds]);

	useEffect(() => {
		if (!surveyPoppedUp) return;
		if (mopinionSRV == null) return;
		if (surveyDoneExpirableLocalStorage.get()) return;

		// Tell Mopinion to popup the survey
		mopinionSRV.openModal(true, surveyID);

		return () => {
			// Tell Mopinion to close the survey
			// When leaving the page
			mopinionSRV.closeModal(surveyID);
		};
	}, [mopinionSRV, surveyID, surveyPoppedUp]);

	useEffect(() => {
		const abortController = new AbortController();

		document.addEventListener(
			'mopinion_shown',
			() => {
				// Increment the count of survey shown
				setSessionSurveyShownCount(getSessionSurveyShownCount() + 1);
				// After showing the survey twice, don't pop it up again
				if (getSessionSurveyShownCount() >= 2) {
					surveyDoneExpirableLocalStorage.set(true);
				}
			},
			{
				signal: abortController.signal,
			}
		);

		// After interacting with the survey, don't pop it up again
		document.addEventListener(
			'mopinion_feedback_sent',
			() => {
				surveyDoneExpirableLocalStorage.set(true);
			},
			{
				signal: abortController.signal,
			}
		);
		document.addEventListener(
			'mopinion_question_answered',
			() => {
				surveyDoneExpirableLocalStorage.set(true);
			},
			{
				signal: abortController.signal,
			}
		);
		document.addEventListener(
			'mopinion_next',
			() => {
				surveyDoneExpirableLocalStorage.set(true);
			},
			{
				signal: abortController.signal,
			}
		);

		return () => {
			abortController.abort();
		};
	}, []);

	// Only load Mopinion JS when the survey is popping up
	if (!surveyPoppedUp) return null;

	return (
		<>
			<LoadMopinion setSRV={setMopinionSRV} />
			{/* Element for Mopinion to render into, this is the default ID for Mopinion */}
			<div id="surveyContent" />
		</>
	);
};
