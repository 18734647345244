'use client';

import { AnalyticsMeta } from './analyticsEventSchema';
import { ReactNode, createContext, useContext } from 'react';

export type AnalyticsMetaContextValue = Omit<
	AnalyticsMeta,
	'urlPath' | 'referrer'
>;

const AnalyticsPageMetaContext = createContext<
	AnalyticsMetaContextValue | undefined
>(undefined);

export const AnalyticsPageMetaClientProvider = ({
	value,
	children,
}: {
	value: AnalyticsMetaContextValue;
	children: ReactNode;
}) => {
	return (
		<AnalyticsPageMetaContext.Provider value={value}>
			{children}
		</AnalyticsPageMetaContext.Provider>
	);
};

export const useAnalyticsPageMeta = (): AnalyticsMetaContextValue => {
	const context = useContext(AnalyticsPageMetaContext);
	if (context == null) {
		throw new Error(
			'useAnalyticsPageMeta must be used within a AnalyticsPageMetaProvider'
		);
	}
	return context;
};
