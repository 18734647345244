'use client';

import { pushEvent } from '../pushEvent';
import { AnalyticsPageAttributes } from '../helpers/getAnalyticsPageAttributes';
import { useEffect, useRef } from 'react';
import { getCleanDataLayerEvent } from '../helpers/getCleanDataLayerEvent';
import { useMeasureReadingIntensity } from '@dop/shared/analytics/readingIntensityAnalytics';
import {
	useReferrer,
	useVisitedPartners,
} from '@/globals/pageWrappers/usePageHistory';
import { useIncrementFeedbackPageCount } from '@/sections/pageFeedback/usePageFeedbackPopup';
import { usePathname } from 'next/navigation';
import { WebApiPageData } from '@/globals/dataCollector/types/dataCollector.types';

type Partners = WebApiPageData['metadata']['op:partner'] | undefined;

const pushPageviewEvent = ({
	analyticsPageAttributes,
	partners = [],
	visitedPartners = new Set(),
	referrer = '',
	pathname,
}: {
	analyticsPageAttributes: AnalyticsPageAttributes;
	partners: Partners;
	visitedPartners: Set<string> | undefined;
	referrer: string | undefined;
	pathname: string;
}) => {
	if (referrer !== '') pushEvent(getCleanDataLayerEvent());

	pushEvent({
		events: {
			category: 'interaction.pageview',
		},
		page: {
			...analyticsPageAttributes,
			urlPath: analyticsPageAttributes.urlPath ?? pathname,
			referrer,
		},
		user: {
			opPartnerList: [...new Set([...visitedPartners, ...partners])],
		},
	});
};

const usePushPageviewEvent = ({
	analyticsPageAttributes,
	partners,
}: {
	analyticsPageAttributes: AnalyticsPageAttributes;
	partners: Partners;
}) => {
	const savedForPageRef = useRef<string>();
	const pathname = usePathname();

	const visitedPartners = useVisitedPartners();
	const referrer = useReferrer();
	useEffect(() => {
		// Only fire page view event if the data has changed
		if (savedForPageRef.current === pathname) {
			return;
		}
		savedForPageRef.current = pathname;

		pushPageviewEvent({
			analyticsPageAttributes,
			partners,
			visitedPartners,
			referrer,
			pathname,
		});
	}, [analyticsPageAttributes, partners, pathname, referrer, visitedPartners]);
};

/**
 * Should come before other components to make sure
 * the pageview is counted before other effects
 */
export const LogPageviewEvent = ({
	analyticsPageAttributes,
	partners,
	wordCount,
	url,
}: {
	analyticsPageAttributes: AnalyticsPageAttributes;
	partners: Partners;
	wordCount: WebApiPageData['wordCount'];
	url: WebApiPageData['url'];
}) => {
	useIncrementFeedbackPageCount();
	usePushPageviewEvent({ analyticsPageAttributes, partners });
	useMeasureReadingIntensity({
		urlPath: url,
		wordCount: wordCount,
	});
	return null;
};
