import { LinkMeta } from '@/globals/routing/location/locationChangeActions';
import environmentConfig from 'moduleAlias/environmentConfig';

export type HrefKind =
	| 'EXTERNAL'
	| 'INTERNAL'
	| 'INTERNAL_STATIC_CONTENT'
	| 'INTERNAL_SELF'
	| 'NON_HTTP';

export type HrefLoadKind = HrefKind | 'PAGE_LOAD';

const staticContentPathnamePrefixes = [
	'/public/',
	'/includes/',
	'/_data/',
	'/sitemap.xml',
	'/sitemapnews.xml',
];

export const getHrefKind = (url: URL, locationURL: URL): HrefKind => {
	if (!['http:', 'https:'].includes(url.protocol)) {
		return 'NON_HTTP';
	}
	if (url.origin !== environmentConfig.originUrl) {
		return 'EXTERNAL';
	}

	if (
		staticContentPathnamePrefixes.some((pathnamePrefix) =>
			url.pathname.startsWith(pathnamePrefix)
		)
	) {
		return 'INTERNAL_STATIC_CONTENT';
	}

	if (url.pathname === locationURL.pathname) {
		return 'INTERNAL_SELF';
	}

	return 'INTERNAL';
};

export const getInternalHref = (url: URL) => {
	return `${url.pathname}${url.search}${url.hash}`;
};

export type LinkInfo = {
	url: URL;
	hrefKind: HrefKind;
	linkMeta: LinkMeta;
};

export const getLinkMeta = (target: HTMLAnchorElement): LinkMeta => {
	const dataset = { ...target.dataset };
	const linkType = target.dataset.linkType as string;
	const text = target.innerText?.trim();

	const linkMeta = { linkType, text, dataset };

	return linkMeta;
};

export const getLinkInfo = (target: HTMLAnchorElement, locationURL: URL) => {
	const { href } = target;
	const url = new URL(href, environmentConfig.originUrl);
	const hrefKind = getHrefKind(url, locationURL);
	const linkMeta = getLinkMeta(target);

	return { url, hrefKind, linkMeta };
};
