import { Translation } from '@/globals/translate/translations';
import { closeIcon } from '@/uiIcons/closeIcon.css';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { focusBox } from '@/uiPrimitives/base/focus.css';
import { box } from '@/uiPrimitives/layout/box';
import { position } from '@/uiPrimitives/layout/position';
import { zIndex } from '@/uiPrimitives/layout/zIndex.css';
import { icon } from '@/uiPrimitives/media/icon';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { ComponentPropsWithoutRef } from 'react';
import { pile } from '@/uiPrimitives/layout/pile';

export const ClosePopup = ({
	closeLabel,
	className,
	...props
}: Omit<ComponentPropsWithoutRef<'button'>, 'children'> & {
	closeLabel: Translation<'Close Feedback Popup'>;
}) => {
	return (
		<button
			{...props}
			className={classes(
				className,
				zIndex({ zIndex: '1' }),
				box({
					backgroundColor: 'positiveBlue',
					inlineSize: '3 | h1',
					blockSize: '3 | h1',
				}),
				pile({ alignItems: 'center', justifyItems: 'center' }),
				position({
					position: 'absolute',
					insetInlineEnd: '0',
					insetBlockStart: '0',
				}),
				focusBox
			)}
			aria-label={closeLabel}
			{...addTestSelector('uiCloseFeedbackPopupButton')}
		>
			<i
				className={classes(
					icon({
						icon: closeIcon,
						inlineSize: '-1 | small',
						blockSize: '-1 | small',
					})
				)}
			/>
		</button>
	);
};
